@import "../../../assets/styles/main";

.Partners {
  margin-top: 10px;
  padding-top: 3px;
  border-top: 1.5px solid #cd7394;
  position: relative;
  z-index: 10;

  .partners-content {
    display: flex;
    justify-content: center;
    overflow: hidden;
    flex-direction: row;

    section {
      text-align: left;
      flex: 1;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      &.organizers {
        flex: 1;

        .rect-wrapper {
          flex-basis: 100%;
        }
      }

      &.partners {
        flex: 4;

        .rect-wrapper {
          flex-basis: 25%;
        }
      }

      &.sponsors {
        flex: 5;

        .rect-wrapper {
          flex-basis: 20%;
        }
      }

      h4 {
        margin-top: 2px;
        margin-bottom: 5px;
        margin-left: 5px;
        margin-right: -5px;
        font-size: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        overflow: hidden;
        color: #b04346;
        font-size: 11px;
        font-family: $font-header;
      }
    }

    .rects {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 10px;
      background: rgba(239, 239, 239, 0.6);
      flex-wrap: wrap;

      .rect-wrapper {
        display: flex;
        min-width: 50px;
        height: 90px;
        padding: 5px;
        box-sizing: border-box;
        .rect {
          flex: 1;
          background: white;
          padding: 5px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;

          .logo {
            flex: 1;
            height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            font-size: 12px;
            text-align: center;
            filter: grayscale(1);
          }
        }
      }
    }
  }

  .decoration {
    margin-top: 15px;
    border-bottom: 1.5px solid #cd7394;
  }
}
