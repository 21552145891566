@import "../../../assets/styles/main";

.animationablelink {
  background: transparent;
  border: none;
  margin: 0;
  font-family: $font-normal;

  &:hover {
    cursor: pointer;
  }
}
