@import "../../../assets/styles/main";

.lastBlogsSet {
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(100, 1%);
  grid-template-rows: repeat(100, 8.5px);

  .preview-wrapper {
    color: black;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    .blogpreview {
      background: white;
      transition: 0.6s ease-out;
      box-shadow: 0 0 8px 2px #cccccc;

      &:hover {
        box-shadow: none;
        transform: scale(0.98);
        box-shadow: 0 0 2px 1px #dddddd;
      }

      .more {
        color: #a31641;
      }
      .line {
        background: #a31641;
      }
    }

    &.c1 {
      grid-row: 1 / 32;

      .blogpreview {
        .blog-preview-image-wrapper {
          flex: 62;
        }

        .content {
          flex: 38;

          .title {
            @include max-line(3);
          }
        }
      }
    }

    &.c2 {
      .blogpreview {
        .blog-preview-image-wrapper {
          flex: 53;
        }
        .content {
          flex: 47;

          .content-top {
            margin-top: 25px;
          }

          .content-description {
            .description {
              @include max-line(3);
            }
          }
        }
      }
    }

    &.c3 {
      grid-row: 34 / 60;

      .blogpreview {
        .blog-preview-image-wrapper {
          flex: 49;
        }
        .content {
          flex: 51;

          .content-description {
            .description {
              @include max-line(3);
            }
          }
        }
      }
    }

    &.c4 {
      grid-column: 1 / 74;

      .blogpreview {
        .blog-preview-image-wrapper {
          flex: 66;
        }
        .content {
          flex: 33;

          .content-title {
            .title {
              @include max-line(4);
            }
          }

          .content-description {
            .description {
              @include max-line(4);
            }
          }
        }
      }
    }

    &.c5 {
      grid-column: 75 / 101;

      .blogpreview {
        .content {
          .content-description {
            .description {
              @include max-line(2);
            }
          }
        }
      }
    }

    &.c1,
    &.c3 {
      grid-column: 1 / 67;
    }

    &.c2 {
      grid-column: 68 / 101;
      grid-row: 1 / 60;
    }

    &.c4,
    &.c5 {
      grid-row: 62 / 101;
    }

    &.c2,
    &.c5 {
      .blogpreview {
        flex-direction: column;
      }
    }
  }
}
