@import "../../../assets/styles/main";
$shadow-stroke: 10px;

.contentbox {
  border-top: 1.5px solid #bbb;

  .margin {
    margin-top: 15px;
  }

  .section {
    display: flex;
    margin-bottom: 25px;
    position: relative;

    &.wysiwygable {
      span,
      p,
      ul,
      ol {
        // display: flex;
      }
    }

    .outer {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .right-side {
      overflow: hidden;
      flex: 1;

      .image-wrapper {
        position: relative;
        padding-top: $shadow-stroke;
        padding-left: $shadow-stroke;
        padding-right: $shadow-stroke * 2;

        .shadow {
          position: absolute;

          &.white-shadow {
            width: calc(100% - #{$shadow-stroke});
            height: calc(100% - #{$shadow-stroke});
            left: 0;
            top: 0;
            background: white;
            z-index: 1;
          }
          &.gray-shadow {
            width: $shadow-stroke;
            height: 80%;
            right: 0;
            top: 10%;
            background: #d9dadb;
            z-index: 0;
          }
        }

        .image {
          flex: 1;
          height: 350px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          z-index: 1;
          display: flex;
          justify-content: flex-end;
          background-color: #aaa;

          .slide-content {
            width: 55%;
            height: 100%;
            background: rgba(0, 0, 0, 0.35);
            color: #eee;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-left: 20px;
            padding-bottom: 45px;
            box-sizing: border-box;
            overflow: hidden;

            .slides-rings {
              display: flex;
              padding: 0 10px;
              margin-bottom: 15px;

              .slide-ring {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                padding: 2px;
                box-sizing: border-box;
                border: 2px solid white;
                margin: 5px 0;
                margin-right: 3px;

                &.current {
                  .slide-ring-inner {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background: #a2224a;
                  }
                }
              }
            }
          }
        }
      }

      .text-box {
        width: 89%;
        margin-left: 5%;
        margin-right: 15px;
        min-height: 320px;
        background: white;
        padding: 20px 50px;
        box-sizing: border-box;
        text-align: left;
        position: relative;
        z-index: 25;

        .header-in-content {
          h2 {
            margin: 0;
            margin-bottom: 10px;
            padding-bottom: 8px;
            color: #a62149;
            font-family: $font-header;
            border-bottom: 1.5px solid #dfb0be;
            font-size: 27px;
          }
        }

        .back {
          text-decoration: none;

          .back-badge {
            margin-top: 10px;
          }
        }

        &.transparent {
          background: transparent;
          margin-left: 0;
          width: 100%;
          padding: 0;
        }

        &.cover-image {
          margin-top: -45px;
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    margin-left: 17.5%;
    padding: 0 110px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
  }
}
