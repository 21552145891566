@mixin max-line($clamp) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $clamp; /* number of lines to show */
  -webkit-box-orient: vertical;
}

$font-normal: "Nunito";
$font-header: "Yeseva One", cursive;

/** responsive */

/*
.menu {
    .options {
      width: 100%;
      margin-right: 0;
      justify-content: space-between;
    }
    .other {
      .Search {
        display: none;
      }
    }
  }
*/
