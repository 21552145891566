@import "../../../assets/styles/main";

.menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  user-select: none;

  .menu-inner {
    width: 100%;
    height: 100%;
    max-width: 1250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.fixed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 50px;

    background: white;
    z-index: 10000;
    padding: 10px 30px;
    box-sizing: border-box;
    box-shadow: 0 1px 12px 2px #888;
    animation: 0.45s show-fixed-menu 1;
    animation-fill-mode: forwards;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @keyframes show-fixed-menu {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .options {
    display: flex;
    align-items: center;
    margin-right: 5%;

    .menu-option {
      text-transform: uppercase;
      padding-right: 10px;
      text-decoration: none;
      color: #888;
      font-size: 14.5px;
      letter-spacing: 0.5px;

      &.basic {
        @include max-line(1);
      }

      &.more {
        display: none;
        position: relative;

        .menu-select-label {
          flex: 1;
        }

        .menu-select {
          position: absolute;
          z-index: 100;
          display: flex;
          flex-direction: column;
          background: #f1f2f3;
          border: 1px solid #888;
          padding: 2px 8px;

          .menu-select-option {
            text-align: left;
            border-bottom: 1px solid #888;
            font-size: 13px;
            padding: 8px 0;

            &:nth-child(1) {
              display: none;
            }

            &:last-child {
              border: none;
            }
          }
        }
      }

      &.selected {
        font-weight: bold;
        color: black;
      }

      &:hover {
        color: #333;
        transition: 0.2s ease-out;
        cursor: pointer;
      }
    }
  }

  .other {
    display: flex;
    flex: 1;

    .mini-logo {
      width: 27px;
      height: 27px;
      border: 1px solid;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url("/assets/images/icon3.png");
      margin-right: 8px;
      display: none;
    }
  }
}
