@import "../../../assets/styles/main";

.Search {
  display: flex;
  flex: 1;

  & > div {
    flex: 1;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #898989;
    position: relative;

    .search-input-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .search-results {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 15px;
      width: 100%;
      background: rgba(249, 249, 249, 0.9);
      z-index: 100;
      border: 1px solid #c6c2c2;
      border-radius: 0 0 3px 3px;
      border-top: none;

      .result {
        display: flex;
        width: 100%;
        height: 35px;
        justify-content: center;
        align-items: center;

        &:hover {
          background: #eee;
          transition: 0.4s ease-out;
          cursor: pointer;

          .title {
            color: #222;
          }
        }

        .result-image {
          width: 35px;
          height: 27px;
          margin: 0 5px;
          background: black;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          border-radius: 2px;
        }

        .result-content {
          flex: 1;
          text-align: left;
          display: flex;
          flex-direction: column;

          .badge {
            margin-right: 5px;
            margin-bottom: 2px;
          }

          .title {
            @include max-line(1);
            font-size: 9px;
            color: #494949;
            font-weight: bold;
          }
        }
      }
    }

    .search-input {
      flex: 1;
      background: transparent;
      border: none;
      padding: 2px 5px;
      font-family: "Nunito";
      font-size: 8px;

      &:focus {
        outline: none;
      }
    }
  }
}
