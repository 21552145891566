@import "../../../assets/styles/main";

.blog {
  .contentbox {
    .section {
      .text-box {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .loading {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 200px;
        }

        .blog-box {
          .blog-padding {
            padding: 0 45px;
          }
          .box-top {
            span {
              font-size: 10px;
              font-family: $font-normal;
            }
            .line {
              border-bottom: 1px solid;
            }
          }

          .box-header {
            font-family: $font-header;
          }

          .box-author-and-sponsor {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;

            .box-author {
              background: #a31641;
              display: flex;
              width: 180px;
              max-width: 70%;
              padding: 4px 0;
              padding-left: 50px;
              box-sizing: border-box;
              overflow: hidden;

              span {
                @include max-line(1);
                font-family: $font-normal;
                color: white;
                font-size: 10px;
                font-weight: bold;
              }
            }

            .box-sponsor {
              width: 50px;
              height: 50px;
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              margin-right: 10px;
              display: flex;
            }
          }

          .box-content {
            font-family: $font-normal;
            font-size: 11pt;
          }
        }
      }
    }
  }
}
