.Arrows {
  height: 100%;
  .arrow {
    cursor: pointer;
    user-select: none;
    position: absolute;
    width: 25px;
    height: calc(100% - 30px);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e5e5e5;

    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
}
