.cookieswindow {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 290px;
  height: 140px;
  max-width: 100%;
  max-height: 100%;
  padding: 15px;
  z-index: 5000;
  box-sizing: border-box;

  .cookie-box {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    align-items: center;
    box-shadow: 0 0 10px 1px #777;

    span {
      font-size: 10px;
      display: flex;
    }

    .buttons {
      margin-top: 10px;

      button {
        margin: 3px;
        background: none;
        border: none;
        font-size: 10px;
        padding: 5px 10px;
        border-radius: 1px;
        border: 1px solid #222;
        cursor: pointer;
        transition: 0.3s ease-out;
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }

        &.privacy-policy {
        }

        &.ok {
          background: #a31641;
          color: white;
        }
      }
    }
  }
}
