@import "../../../assets/styles/main";
.leftheader {
  width: 17.5%;
  position: relative;
  min-height: 320px;
  user-select: none;

  .extended-line-wrapper {
    position: relative;
    margin-top: 59px;

    .extended-line {
      position: absolute;
      right: 0;
      width: 40%;
      margin-right: -23%;
      height: 2px;
      background: #a62149;
    }
  }

  .line-box {
    padding: 10px 0;
    position: relative;
    z-index: 5;

    .line {
      height: 2px;
      box-sizing: border-box;
      width: 130%;
      text-align: right;
      display: flex;
      justify-content: flex-end;

      &.transparent {
        background: transparent;
      }

      .dotted {
        width: 60%;
        height: 100%;
        margin-top: 1px;
        border-top: 1.5px dotted #868686;
      }

      .inner {
        width: 40%;
        height: 100%;
        background-color: #7d1737;
      }
    }
  }

  .gray-square-box {
    display: flex;
    justify-content: flex-end;
    padding-top: 35px;
    position: absolute;
    z-index: 0;
    box-sizing: border-box;
    width: 100%;

    .gray-square {
      background: #d8d9da;
      width: 60%;
      height: 300px;
    }
  }

  h2 {
    position: relative;
    z-index: 10;
    text-align: right;
    margin: 0;
    margin-top: 45px;
    padding-right: 20px;
    overflow: hidden;
    font-size: 37px;
    letter-spacing: 0.5px;
    font-weight: normal;
    font-family: $font-header;
  }
}
