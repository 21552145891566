@import "../../../assets/styles/main";

.footer {
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;

  .green-map-wrapper {
    max-width: 1250px;
    width: 100%;
    height: 520px;
    margin-top: -120px;
    box-sizing: border-box;
    position: absolute;
    z-index: 0;
    padding: 0 30px;

    .green-map {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 0;
      background-image: url("../../../assets/images/Green_Map.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  .inner {
    position: relative;
    z-index: 10;
    max-width: 1250px;
    padding: 25px 50px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    text-align: left;

    & > .footer-top {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
    }

    & > .footer-bottom {
      display: flex;
      align-items: flex-start;

      .footer-bottom-content {
        display: flex;
      }
    }

    & > .additional-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0;
      background: white;
      border: 1.5px solid #aaa;

      .additional-bottom-image {
        max-width: 100%;
        width: 680px;
        height: 140px;
        background-image: url("../../../assets/images/niw.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    section {
      flex: 1;
      overflow: hidden;
      $header-height: 25px;
      padding-right: 2px;
      box-sizing: border-box;

      h4 {
        height: $header-height;
        display: flex;
        align-items: center;
        font-family: $font-header;
        font-weight: normal;

        .icon {
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url("../../../assets/images/icon.png");
          width: $header-height;
          height: $header-height;
          margin-right: 6px;
        }
        font-size: 11px;
        margin: 0;
      }

      .line {
        margin: 10px 0;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        a {
          text-decoration: none;
          color: initial;
        }
        li {
          margin: 0;
          padding: 0;
          margin-bottom: 2px;
          font-family: $font-normal;
        }
      }

      a,
      span,
      li {
        font-size: 9px;
      }

      .shareholder {
        padding-right: 5px;
        margin-right: 5px;
        border-right: 1px solid;
        text-decoration: none;
        color: black;

        &:last-child {
          border: none;
        }
      }

      .line {
        width: 20px;
        height: 2px;
        background: #444;

        &.long {
          width: 90%;
        }
      }

      &.footer-menu {
        order: 1;
        a {
          color: black;
          text-decoration: none;
        }
      }

      &.footer-short {
        order: 2;
      }

      &.footer-contact {
        order: 3;
        & > div {
          display: flex;
        }

        h3 {
          font-size: 13px;
          font-weight: 800;
        }
      }

      &.footer-newsletter {
        order: 4;

        .footer-newsletter-inner {
          display: flex;
          flex-direction: column;
        }
        .newsletter-input-wrapper {
          width: 65%;
          display: flex;
          border: 1px solid;
          padding: 0.5px;
          position: relative;
          overflow: hidden;
          display: flex;
          align-items: center;
          background: rgba(255, 255, 255, 0.3);
          margin-right: 10px;
        }
        input {
          border: 1px solid black;
          padding: 5px 5px;
          font-size: 7px;
          font-family: $font-normal;
          background: transparent;
          width: 100%;

          &:focus {
            outline: none;
          }
        }

        button {
          position: absolute;
          background: none;
          border: none;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          right: 0;

          &:focus {
            outline: none;
          }
        }
      }

      .social-media {
        display: flex;

        .square {
          width: 22px;
          height: 22px;
          background: rgba(255, 255, 255, 0.5);
          border: 1.5px solid #555;
          margin-right: 4px;
          border-radius: 1px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;

          svg {
            color: #444;
          }
        }
      }
    }
  }
}
