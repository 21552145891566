@import "../../../assets/styles/main";

$content-height: 120px;

.tile {
  flex: 1 0 25%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  &.extend {
    & > .inner {
      .info-box {
        margin-top: -125px;

        .content {
          height: 235px;

          .description {
            display: flex;

            span {
              @include max-line(15);
            }
          }
        }

        & > .inner {
          .shadow-box-wrapper {
            height: 245px;
          }
        }
      }
    }
  }

  & > .inner {
    flex: 1;
    padding: 0 5px;
    position: relative;
    overflow: hidden;

    .photo-wrapper {
      width: 100%;
      height: 140px;
      border-radius: 2px;
      overflow: hidden;
      z-index: 0;
    }

    .photo {
      width: 100%;
      height: 100%;
      border-radius: 2px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transition: 0.3s ease-out;
    }

    .info-box {
      text-align: left;
      width: 100%;
      margin-top: -10px;
      display: flex;
      z-index: 10;
      position: relative;
      transition: 0.4s ease-out;

      & > .inner {
        flex: 1;
        position: relative;
        z-index: 10;
        padding: 0 14px;
        overflow: hidden;

        .shadow-box-wrapper {
          width: 100%;
          height: $content-height;
          display: flex;
          position: absolute;
          z-index: 0;
          left: 0;
          top: 0;
          padding: 7px;
          padding-bottom: 20px;
          box-sizing: border-box;
          transition: 0.4s ease-out;

          .shadow-box {
            flex: 1;
            background: rgba(170, 170, 170, 0.4);
          }
        }

        .white-box {
          position: relative;
          z-index: 10;
          background: white;
        }

        & > .additional-info {
          width: 100%;
          display: flex;
          text-align: center;
          justify-content: center;
          margin-bottom: 8px;
        }
      }

      .content {
        position: relative;
        padding: 10px 7px;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        height: $content-height;
        box-sizing: border-box;
        transition: 0.4s ease-out;

        .more {
          position: absolute;
          bottom: 0;
          border-right: 1.5px solid #dba6b7;
          padding: 5px 0;
          padding-right: 12px;
          cursor: pointer;

          span {
            color: #aa2149;
            font-size: 8px;
            font-weight: 700;
          }
        }

        .title {
          display: flex;
          margin-top: 5px;
          margin-bottom: 3px;

          span {
            @include max-line(1);
            font-size: 12.5px;
            font-family: $font-header;
            color: #a62149;
          }
        }

        .line {
          margin-bottom: 15px;
          .line-inner,
          .line-shadow {
            width: calc(100% + 27px);
            height: 1.5px;
            background: #dba6b7;
            margin-left: -27px;
          }

          .line-shadow {
            background: #f7edf0;
          }
        }

        .description {
          display: flex;

          span {
            @include max-line(4);
            font-size: 8px;
            font-weight: 600;
          }
        }
      }
    }
  }
  .tile-bottom {
    width: 100%;
    height: 30px;
    background: #f1f2f3;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
}
