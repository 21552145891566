@import "../../../assets/styles/main";

.blogpreview {
  flex: 1;
  box-sizing: border-box;
  display: flex;
  text-align: left;
  background: transparent;
  border: none;
  text-decoration: none;
  color: inherit;

  &:hover {
    .image {
      transform: scale(1.1);
    }

    .content {
      .content-more {
        .line {
          width: 50%;
          opacity: 0.5;
        }
      }
    }
  }

  .blog-preview-image-wrapper {
    display: flex;
    flex: 1;
    overflow: hidden;

    .image {
      flex: 1;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transition: 0.35s ease-out;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    box-sizing: border-box;

    .padding {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
    }

    .content-top {
      display: flex;
      border-bottom: 1px solid;
      margin-top: 7px;
      margin-bottom: 7px;
      padding-bottom: 1px;
      color: #bbb;
      span {
        font-size: 7px;
      }
      .section-name {
      }

      .date {
      }
    }

    .content-title {
      .title {
        @include max-line(2);
        padding: 0;
        margin: 0;
        font-size: 14px;
        margin-top: 15px;
        margin-bottom: 15px;
        font-family: $font-header;
        font-weight: normal;
      }
    }

    .content-description {
      .description {
        @include max-line(4);
        padding: 0;
        font-size: 8px;
        font-family: $font-normal;
      }
    }

    .content-more {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      .more {
        font-size: 8px;
        font-family: $font-normal;
        font-weight: bold;
        padding-left: 10px;
      }

      .line {
        width: 35%;
        height: 3px;
        background: white;
        max-width: 80%;
        margin-top: 10px;
        transition: 0.3s ease-out;
        display: flex;
      }
    }

    .content-tags {
      margin-top: 3px;
      padding: 0 10px;
      display: flex;
      flex-wrap: wrap;

      .tag {
        color: #a41843;
        margin-right: 3px;
        text-transform: lowercase;

        span {
          @include max-line(1);
          font-size: 8px;
        }
      }
    }
  }
}
