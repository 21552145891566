@import "../../../assets/styles/main";

.badge-wrapper {
  display: flex;

  &.disabled {
    span {
      color: #888;
    }

    .half-square {
      border-color: #888;
    }
  }

  &.no-frame {
    .badge {
      .inner {
        width: 150px;
        max-width: 90%;
        padding: 2px 0;
        border-top: 2px solid #a62149;
        text-align: left;
        justify-content: flex-start;
        .half-square {
          display: none;
        }
        span {
          font-family: $font-normal;
          font-size: 10px;
          color: #a62149;
        }
      }
    }
  }
}
.badge {
  position: relative;
  display: inline-block;
  padding: 1px 0;
  box-sizing: border-box;

  .inner {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
  }

  .half-square {
    position: absolute;
    width: 1.5px;
    height: 1.5px;
    border: 1.5px solid #a31641;

    &.top-left {
      top: 0;
      left: 0;
      border-right: none;
      border-bottom: none;
    }

    &.top-right {
      top: 0;
      right: 0;
      border-left: none;
      border-bottom: none;
    }

    &.bottom-left {
      bottom: 0;
      left: 0;
      border-top: none;
      border-right: none;
    }

    &.bottom-right {
      bottom: 0;
      right: 0;
      border-top: none;
      border-left: none;
    }
  }

  span {
    @include max-line(1);
    font-size: 9px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    color: #a31641;
  }
}
