.social-medias {
  display: flex;
  margin-left: 9%;

  .square {
    width: 22px;
    height: 22px;
    border: 1.5px solid #999;
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
    margin: 0 2.5px;
    justify-content: center;
    align-items: center;
    color: #888;

    &:last-child {
      margin-right: 0;
    }
  }
}
