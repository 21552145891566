@import "../../../assets/styles/main";

.blogs {
  .blog-item {
    .sections {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .section {
        flex: 1;
        margin: 2px;
        padding: 4px 6px;
        background: #d9dadb;
        border-radius: 2px;
        text-align: center;
        display: flex;
        justify-content: center;
        border: none;

        span {
          @include max-line(1);
          font-size: 10px;
          color: #222;
          font-family: $font-normal;
        }

        &:hover {
          cursor: pointer;
        }

        &.selected {
          background: #a2224a;
          font-weight: bold;
          span {
            color: white;
          }
        }

        &.lvl2 {
          flex-basis: 30%;
        }
      }
    }

    .loading-box {
      height: 150px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .right-side {
    .content-top-image {
      .image {
        min-height: 270px;
      }
    }
  }

  .blog-bottom {
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .slide-content {
    .blogs-slide {
      flex: 0;

      .content-title {
        margin-bottom: 0;
        .title {
          font-size: 22px;
          letter-spacing: 1px;
          margin-bottom: 10px;
        }
      }

      .content-description {
        .description {
          @include max-line(2);
          font-size: 12px;
          margin-top: 0;
        }
      }
    }
  }

  .text-box {
    .blogs-blog {
      margin-bottom: 15px;

      .content-more {
        .more {
          color: #a41843;
        }
        .line {
          background: #a41843;
        }
      }
    }
  }
}
